import { API } from "aws-amplify";
export const GetAllIssuerTypeRESTAPI = {
  data() {
    return {
      GetAllIssuerTypeList: [],
    };
  },
  methods: {
    async GetAllIssuerTypeMethod() {
      var self = this;
      const path = "listissuertypes";
      const myInit = {
        body: {
          command: "listIssuerTypes",
        },
      };
      API.post("SURECREDS", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            self.GetAllIssuerTypeList = response.data.items;
          } else {
            self.GetAllIssuerTypeList = [];
            self.noDataText = response.errorMessage;
          }
        })
        .catch(() => {});
    },
  },
};
