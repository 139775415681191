<template>
  <v-app>
    <v-app-bar elevation="1" app color="white">
      <v-card max-width="190px" class="elevation-0">
        <v-img src="@/assets/SureCredsLogo.png"></v-img>
      </v-card>
      <v-divider vertical class="mx-3"></v-divider>
      <div class="largeFontSizePrimaryGreen font-weight-bold">Register With SureCreds</div>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-main>
      <div>
        <Snackbar :SnackBarComponent="SnackBarComponent" />
        <IssuerOnboardedSuccess :IssuerOnboardedSuccessDialog="IssuerOnboardedSuccessDialog" />
        <v-card class="elevation-0">
          <v-card-text v-if="current_view == 'CREATE'">
            <v-form ref="form">
              <v-row>
                <v-col cols="12" sm="12" md="12" xs="12" class="pt-6">
                  <div class="heading3 mx-1 font-weight-bold">Business Details :</div>
                </v-col>
                <v-col cols="12" sm="12" md="6" xs="12" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="Issuer Name*"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="create_issuer.issuer_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" xs="12" class="py-0">
                  <v-select
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="Issuer Type*"
                    :rules="[(v) => !!v || 'Required']"
                    item-text="issuer_type"
                    item-value="issuer_type"
                    :items="GetAllIssuerTypeList"
                    v-model="create_issuer.issuer_type"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                  <v-select
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="Country *"
                    :items="GetAllCountriesList"
                    item-value="country_name"
                    item-text="country_name"
                    :rules="[(v) => !!v || 'Country is required']"
                    v-model="create_issuer.country"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title v-text="` ${item.country_name}`"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                  <v-select
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="Please Select Identification Type"
                    :items="identificationTypeItems"
                    v-model="create_issuer.identification_type"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="Business Identification No. *"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="create_issuer.identification_number"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" xs="12" class="pt-0 mt-n3">
                  <div class="heading3 mx-1 font-weight-bold">Registered Business Address :</div>
                </v-col>
                <v-col cols="12" sm="12" md="6" xs="12" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="Address 1 *"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="create_issuer.issuer_address_1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="6" xs="12" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="Address 2 *"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="create_issuer.issuer_address_2"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                  <v-select
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="State/ County *"
                    :items="stateList"
                    item-text="name"
                    item-value="name"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="create_issuer.state"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                  <v-select
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="District *"
                    :items="citiesList"
                    item-text="name"
                    item-value="name"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="create_issuer.city"
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                  <v-text-field outlined dense class="field_height field_label_size FontSize" label="Area *" :rules="[(v) => !!v || 'Required']" v-model="create_issuer.district"></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="Postal Code *"
                    :rules="[(v) => !!v || 'Required', (v) => /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9]{0,6}$/.test(v) || 'Postal Code Is Invalid']"
                    v-model="create_issuer.postal_code"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="12" xs="12" class="pt-0">
                  <div class="heading3 mx-1 font-weight-bold mt-n3">Business Contact Details :</div>
                </v-col>
                <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="Name *"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="create_issuer.contact_user_name"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="4" xs="12" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="Email ID *"
                    :rules="[(v) => !!v || 'Required', (v) => /.+@.+/.test(v) || 'Invalid']"
                    v-model="create_issuer.contact_email_id"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                  <v-select
                    outlined
                    dense
                    :items="GetAllCountriesList"
                    item-value="country_code"
                    item-text="country_code"
                    class="field_height field_label_size FontSize"
                    label="Country Code *"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="create_issuer.country_code"
                  >
                    <template slot="selection" slot-scope="{ item }">
                      {{ item.country_code }}
                    </template>
                    <template slot="item" slot-scope="{ item }">{{ item.country_name }}({{ item.country_code }}) </template>
                  </v-select>
                </v-col>
                <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    class="field_height field_label_size FontSize"
                    label="Contact Number *"
                    :rules="[(v) => /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9 +-]{0,64}$/.test(v) || 'Invalid', (v) => (v && v.length < 11) || 'Invalid', (v) => (v && v.length > 9) || 'Invalid']"
                    v-model="create_issuer.contact_number"
                  ></v-text-field>
                </v-col>
                <!-- <v-col cols="12" sm="12" md="12" xs="12" class="pt-0">
                  <div class="heading3 mx-1 font-weight-bold mt-n3">Verification Details :</div>
                </v-col>
                <v-col cols="12" sm="12" md="3" xs="12" class="py-0">
                  <v-checkbox dense class="my-0 py-0" label="Enable Verification Requests" v-model="create_issuer.enable_verification_requests"></v-checkbox>
                </v-col>
                <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    readonly
                    class="field_height field_label_size FontSize"
                    label="Currency Type*"
                    :items="currencyItems"
                    :rules="[(v) => !!v || 'Required']"
                    v-model="create_issuer.currency_type"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="12" md="2" xs="12" class="py-0">
                  <v-text-field
                    outlined
                    dense
                    :disabled="create_issuer.enable_verification_requests == false"
                    class="field_height field_label_size FontSize"
                    label="Verification Fee *"
                    :rules="[(v) => /^(?=[a-zA-Z0-9@._%+-]{0,30}$)[0-9]{0,64}$/.test(v) || 'Invalid']"
                    v-model="create_issuer.issuer_verification_fee"
                  ></v-text-field>
                </v-col> -->
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="pt-0 pb-4 mr-2">
            <v-spacer></v-spacer>
            <v-btn small outlined class="borderRadius text-capitalize" color="secondary" @click="$router.push('Home')"><v-icon small class="mr-1">mdi-close</v-icon>Cancel </v-btn>
            <v-btn small :loading="loading" class="borderRadius text-capitalize" color="secondary" @click="validateFormMethod()"><v-icon small class="mr-1">mdi-check</v-icon>Confirm </v-btn>
          </v-card-actions>
        </v-card>
      </div>
    </v-main>
  </v-app>
</template>
<script>
import { GetAllCountriesRESTAPI } from "@/mixins/GetAllCountriesRESTAPI.js";
import { GetAllIssuerTypeRESTAPI } from "@/mixins/GetAllIssuerTypeRESTAPI.js";
import { GetAllCountryCityStates } from "@/mixins/GetAllCountryCityStates.js";
import { API } from "aws-amplify";
import Snackbar from "@/components/Extras/Snackbar.vue";
import IssuerOnboardedSuccess from "@/components/OnBoardIssuer/Dialogs/IssuerOnboardedSuccess.vue";
export default {
  props: {
    dialogCreateIssuerUser: Boolean,
  },
  mixins: [GetAllCountryCityStates, GetAllCountriesRESTAPI, GetAllIssuerTypeRESTAPI],
  components: {
    Snackbar,
    IssuerOnboardedSuccess,
  },
  data: () => ({
    imgSrc: null,
    height: 0,
    search: null,
    current_view: "CREATE",
    modify_bool: false,
    uploadedImage: false,
    selectedFile: null,
    SnackBarComponent: {},
    create_issuer: {
      issuer_name: "",
      issuer_type: "",
      identification_type: "",
      identification_number: "",
      issuer_address_1: "",
      issuer_address_2: "",
      country: "",
      state: "",
      city: "",
      district: "",
      postal_code: "",
      contact_email_id: "",
      contact_user_name: "",
      country_code: "",
      contact_number: "",
      temporary_password: "",
      enable_verification_requests: false,
      issuer_verification_fee: 0,
      currency_type: "",
    },
    typeOfBusinessItems: ["issuer", "Limited Liability Partnership", "Sole Proprietorship", "Individual"],
    stateList: [],
    citiesList: [],
    identificationTypeItems: [],
    loading: false,
    GetAllCountriesList: [],
    currencyItems: ["INR", "DOLLAR"],
    IssuerOnboardedSuccessDialog: false,
  }),
  watch: {
    "create_issuer.enable_verification_requests"(val) {
      if (val == false) {
        this.create_issuer.issuer_verification_fee = 0;
      }
    },
    "create_issuer.country"(val) {
      if (val != undefined && val != null && val != "") {
        this.identificationTypeItems = this.GetAllCountriesList.filter((item) => item.country_name == val)[0].identification_types;
        this.getAllStatesMethod(val);
        this.create_issuer.state = "";
        this.create_issuer.city = "";
        this.create_issuer.currency_type = this.countryList.filter((country) => country.name == val)[0].currency;
      }
    },
    "create_issuer.state"(val) {
      if (val != null && val != undefined && val != "" && this.GetAllCountriesList.length != 0) {
        this.getAllCitiesMethod(val);
      }
    },
  },
  mounted() {
    this.GetAllCountriesMethod();
    this.getAllCountriesMethod();
    this.GetAllIssuerTypeMethod();
  },
  methods: {
    validateFormMethod() {
      if (this.$refs.form.validate()) {
        this.createIssuerMethod();
      } else {
        this.SnackBarComponent = {
          SnackbarVmodel: true,
          SnackbarColor: "red",
          Top: true,
          SnackbarText: "Fields marked with asterisk(*) are mandatory",
        };
      }
    },
    async createIssuerMethod() {
      this.loading = true;
      const path = "onboardissuer";
      const myInit = {
        body: {
          command: "createIssuer",
          city: this.create_issuer.city,
          contact_email_id: this.create_issuer.contact_email_id,
          contact_number: this.create_issuer.contact_number,
          contact_user_name: this.create_issuer.contact_user_name,
          country: this.create_issuer.country,
          country_code: this.create_issuer.country_code,
          currency_type: this.create_issuer.currency_type,
          district: this.create_issuer.district,
          enable_verification_requests: this.create_issuer.enable_verification_requests,
          identification_number: this.create_issuer.identification_number,
          identification_type: this.create_issuer.identification_type,
          issuer_address_1: this.create_issuer.issuer_address_1,
          issuer_address_2: this.create_issuer.issuer_address_2,
          issuer_name: this.create_issuer.issuer_name,
          issuer_type: this.create_issuer.issuer_type,
          issuer_verification_fee: this.create_issuer.issuer_verification_fee,
          postal_code: this.create_issuer.postal_code,
          state: this.create_issuer.state,
          partner_id: "PTNR-1",
        },
      };
      API.post("SURECREDS", path, myInit)
        .then((response) => {
          if (!response.errorType) {
            if (response.status == "Success") {
              this.IssuerOnboardedSuccessDialog = true;
            }
          } else {
            this.SnackBarComponent = {
              SnackbarVmodel: true,
              SnackbarColor: "red",
              Top: true,
              SnackbarText: response.errorMessage,
            };
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>
