import { API } from "aws-amplify";
export const GetAllCountriesRESTAPI = {
  data() {
    return {
      GetAllCountriesList: [],
    };
  },
  methods: {
    async GetAllCountriesMethod() {
      var self = this;
      const path = "listcountries";
      const myInit = {
        body: {
          command: "listCountries",
          country_status:'ACTIVE'
        },
      };
      console.warn("myInit",myInit)
      API.post("SURECREDS", path, myInit)
        .then((response) => {
          console.warn("Check Response",response)
          if (!response.errorType) {
            self.GetAllCountriesList = response.data.items;
          } else {
            self.GetAllCountriesList = [];
            self.noDataText = response.errorMessage;
          }
        })
        .catch((error) => {
          console.warn("Check The Response",error)
        });
    },
  },
};
