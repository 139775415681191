<template>
  <v-app>
    <v-card class="elevation-0">
      <v-row>
        <v-col cols="12" xs="12" sm="12" md="12" class="mt-6" align="center">
          <v-card outlined max-width="400px">
            <v-img src="@/assets/PaymentSuccessful.jpeg"></v-img>
          </v-card>
          <h1>Payment Successfull !!!</h1>
          <div class="largeFontSize">
            Redirecting to home page in <v-progress-circular :value="value" color="primary" :rotate="360" :size="100" :width="10">{{ seconds }}</v-progress-circular> seconds
            <br />
            <v-btn outlined color="primary" @click="routeToHome()">
              <v-icon>mdi-home</v-icon>
              Go To Home Page
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </v-app>
</template>
<script>
export default {
  data: () => ({
    seconds: 10,
    value: 10,
    setInterVal:null
  }),
  mounted() {
    this.setIntervalMethod();
  },
  methods: {
    routeToHome(){
      clearInterval(this.setInterVal);
      this.$router.push("/Home")
    },
    setIntervalMethod() {
      var self = this;
      self.setInterVal = setInterval(function () {
        self.seconds -= 1;
        self.value += 10;
        if (self.seconds == 1) {
          clearInterval(self.setInterVal);
          self.$router.push("/Home")
        }
      }, 1000);
    },
  },
};
</script>
