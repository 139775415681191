<template>
  <div>
    <v-dialog persistent v-model="IssuerOnboardedSuccessDialog" max-width="400px">
      <v-card class="elevation-0">
        <v-toolbar dark color="primary" dense class="elevation-0">Success </v-toolbar>
        <v-card-text class="text-center">
          <v-icon color="primary" size="50px" class="mt-5">mdi-account-hard-hat</v-icon>
          <div>Issuer Onboarded Successfully!!</div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn small color="primary" @click="$router.push('Login')">Ok</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  props: {
    IssuerOnboardedSuccessDialog: Boolean,
  },
};
</script>
